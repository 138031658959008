<script>
import { SuawButton } from "@suaw/suaw-component-library";
import * as Api from "./apiTest.js";
export default {
  name: "ApiTest",
  components: {
    SuawButton
  },
  methods: {
    async onClick() {
      let result = await Api.apiMethod();
      this.$log.debug("result Api", result);
    }
  }
};
</script>

<template>
  <SuawButton button-text="Click" @click="onClick" />
</template>
