//change how this file works according to your needs
import { DraftPost } from "@/features/discussions/operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { Logger, DEBUG } from "@/utils/plugins/logging";
import { hasuraClient } from "@/utils/graphql/apollo.js";

const $log = new Logger("apiMethod", { level: DEBUG });

export async function apiMethod() {
  $log.debug("apiMethod");
  try {
    var post = {
      content: [
        {
          type: "paragraph",
          content: [
            {
              text: "This is a test post",
              type: "text"
            }
          ],
          attrs: {
            textAlign: "left"
          }
        }
      ],
      type: "doc"
    };
    const response = await hasuraClient.mutate({
      mutation: DraftPost,
      variables: {
        bodyJson: post,
        channelId: "xx"
      }
    });
    $log.debug("apiMethod response", response);
    const errors = response.data.result.errors;
    if (errors?.length > 0) {
      const validationReasonMap = new Map([
        [BE.EMPTY_FIELD, "a"],
        [BE.PERMISSION, "b"],
        [BE.NOT_EXIST, "c"],
        [BE.NUMBER_TOO_LARGE, "d"],
        [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
      ]);
      const executionReasonMap = new Map([
        [BE.NOT_EXIST, "j"],
        [BE.INSERT_FAILED, "k"],
        [BE.UPDATE_FAILED, "l"],
        [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
      ]);
      const result = {
        success: false,
        validationFailReason: BE.validationFailReason(errors), //can be referenced by caller to override the default error message
        executionFailReason: BE.executionFailReason(errors), //can be referenced by caller to override the default error message
        error: BE.getErrorMessage(errors, validationReasonMap, executionReasonMap)
      };
      $log.debug(result);
      return result;
    }
    const newPostId = response.data.result.id;
    return { success: true, newPostId };
  } catch (error) {
    $log.error("apiMethod error", error);
    return { success: false, error: BE.fallbackErrorMessage };
  }
}
